import React from "react";
import { FaCheck, FaStar } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import "../css/ComparisonGrid.css";

const getCellClass = (value: any) => {
    if (React.isValidElement(value) && value.type === FaCheck) return "cell-check";
    if (React.isValidElement(value) && value.type === FaXmark) return "cell-xmark";
    if (
        React.isValidElement(value) &&
        value.type === FaStar
    ) {
        return "cell-star";
    }
    if (Array.isArray(value) && value.some((child) => React.isValidElement(child) && child.type === FaStar)) {
        return "cell-star";
    }
    return "cell-none";
};

const comparisonData = {
    headings: ["Self Teaching", "Traditional Schooling", "Bootcamps", "Beyond Code"],
    rows: [
        {
            label: "Form a clear Learning Path",
            values: [<FaXmark />, <FaXmark />, <FaXmark />, <FaCheck />],
        },
        {
            label: "Find which coding language is right for you",
            values: [<FaXmark />, <FaXmark />, <FaXmark />, <FaCheck />],
        },
        {
            label: "Learn how to code",
            values: [<FaCheck />, <FaCheck />, <FaCheck />, <FaXmark />],
        },
        {
            label: "Coding Cheat Sheets & Reference Guides",
            values: [<FaXmark />, <FaXmark />, <FaXmark />, <FaCheck />],
        },
        {
            label: "Friendly Community of Developers",
            values: [<FaXmark />, <FaXmark />, <FaXmark />, <FaCheck />],
        },
        {
            label: "Coding Resumes & Cover Letters",
            values: [<FaXmark />, <FaXmark />, <FaXmark />, <FaCheck />],
        },
        {
            label: "Help w/ Portfolio Building",
            values: [<FaXmark />, <FaXmark />, <FaXmark />, <FaCheck />],
        },
        {
            label: "Technical Interview Prep",
            values: [<FaXmark />, <FaXmark />, <FaXmark />, <FaCheck />],
        },
        {
            label: "Non-Technical Interview Prep",
            values: [<FaXmark />, <FaXmark />, <FaXmark />, <FaCheck />],
        },
        // {
        //     label: "Price",
        //     values: ["Varies", "~20K -> 30K/year", "~$10,000", "$30/month"],
        // },
        // {
        //     label: "User Satisfaction",
        //     values: [
        //         <FaStar />,
        //         [<FaStar key="1" />, <FaStar key="2" />, <FaStar key="3" />],
        //         [<FaStar key="1" />, <FaStar key="2" />, <FaStar key="3" />],
        //         [
        //             <FaStar key="1" />,
        //             <FaStar key="2" />,
        //             <FaStar key="3" />,
        //             <FaStar key="4" />,
        //             <FaStar key="5" />,
        //         ],
        //     ],
        // },
    ],
};

export const ComparisonGrid: React.FC = () => {
    return (
        <div className="comparison-grid-container">
            <div className="comparison-grid">
                <h3>
                    There are <span className="highlight">1,000,000</span> places that teach you to code
                </h3>
                <h3>
                    Only <span className="highlight">1</span> place guides you to becoming a professional developer
                </h3>
                <div className="table-container">
                    <table>
                        <thead>
                        <tr>
                            <th></th>
                            {comparisonData.headings.map((heading, index) => (
                                <th key={index}>{heading}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {comparisonData.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{row.label}</td>
                                {row.values.map((value, colIndex) => (
                                    <td key={colIndex} className={getCellClass(value)}>{value}</td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};