import React from 'react';
import '../css/EmailSubscribeInput.css';
import DOM_AVATAR from "../images/testimonials/Dominik-Jasarevic-Avatar.webp"
import KAZ_AVATAR from "../images/testimonials/Shane-K-Avatar.webp"
import RYAN_AVATAR from "../images/testimonials/RyanTAvatar.webp"
import {FaStar} from "react-icons/fa";

export const EmailSubscribeInput: React.FC<any> = ({showReviews}) => {
    return (
        <div className={'email-subscribe-input'}>
            <iframe src="https://embeds.beehiiv.com/c74f28af-6518-443a-9e78-7484a0fd7735?slim=true"
                    className={'bee-hive-sign-up'}
                    data-test-id="beehiiv-embed" height="52" frameBorder="0" scrolling="no"></iframe>
            <div className="email-sign-up-under-form-container">
                <HeroReviews/>
                <p className="email-sign-up-page-privacy-text">We’ll never share your info with anyone.</p>
            </div>
        </div>
    );
};

export const HeroReviews = () => {
    return (
        <div className={'hero-review-container'}>
            {Array(5).fill(null).map((_, i) => (
                <FaStar key={i} className="hero-testimonial-star-icon"/>
            ))}
            <img id={'hero-testimonial-1'} src={KAZ_AVATAR}/>
            <img id={'hero-testimonial-2'} src={DOM_AVATAR}/>
            <img id={'hero-testimonial-3'} src={RYAN_AVATAR}/>
        </div>
    )
}