import React, {useState} from 'react';
import '../css/LearningPathContent.css'
import {ICONS} from "../util/IconHelper";
import {useNavigate} from "react-router-dom";
import {trackEvent} from "../util/TrackingHelper";
import {ALL_CONTENT} from "../constants/LearningPathsConfig";
import { FaStar } from "react-icons/fa";

export const AllContent: React.FC<any> = ({isAuthenticated}) =>  {
    const [courseHeader, setCourseHeader] = useState<string>(ALL_CONTENT.allCourses[0].header);
    const [interviewHeader, setInterviewHeader] = useState<string>(ALL_CONTENT.allInterviews[0].header);
    const [resourceHeader, setResourceHeader] = useState<string>(ALL_CONTENT.allResources[0].header);
    return (
        <div className={'all-content-container'}>
            <ContentType selectedHeader={courseHeader} setSelectedHeader={setCourseHeader} header={"Courses"} contentType={"courses"} allContent={ALL_CONTENT.allCourses} isAuthenticated={isAuthenticated}/>
            <ContentType selectedHeader={interviewHeader} setSelectedHeader={setInterviewHeader} header={"Interviews"} contentType={"interviews"} allContent={ALL_CONTENT.allInterviews} isAuthenticated={isAuthenticated}/>
            <ContentType selectedHeader={resourceHeader} setSelectedHeader={setResourceHeader} header={"Resources"} contentType={"resources"} allContent={ALL_CONTENT.allResources} isAuthenticated={isAuthenticated}/>
        </div>
    )
}

interface ContentTypeProps {
    selectedHeader: string;
    setSelectedHeader: (header: string) => void;
    header: string;
    contentType: string;
    allContent: any [];
    isAuthenticated: boolean;
}

export const ContentType: React.FC<ContentTypeProps> = ({selectedHeader, setSelectedHeader, header, contentType, allContent, isAuthenticated}) =>  {
    return (
        <div className="learning-path-content-container">
            <h3 className="learning-path-overall-header">{header}</h3>
            <div className="learning-path-header-list">
                {allContent.map((contentList, index) => (
                    <h3
                        key={index}
                        className={`learning-path-header${index === 0 ? ' first' : ''}${selectedHeader === contentList.header ? ' selected-header' : ''}`}
                        onClick={() => setSelectedHeader(contentList.header)}
                    >
                        {contentList.header}
                    </h3>
                ))}
            </div>
            <div className="learning-path-container courses">
                {allContent.map((contentList: any, index) => (
                    <>
                        {selectedHeader === contentList.header && (
                            <div className={'learning-path-list-content'}>
                                    {contentList.content.map((content: any, index: number) => (
                                        <LearningGridItem key={index} item={content} type={`${contentType}`}
                                                          page={`${contentList.pageTracking}`} typeTracking={`${contentType}`}
                                                          isAuthenticated={isAuthenticated}/>
                                    ))}
                            </div>
                        )}
                    </>
                ))}
            </div>
        </div>
    );
}

const LearningGridItem: React.FC<any> = ({item, type, page, typeTracking, isAuthenticated}) => {
    const navigate = useNavigate();
    let navigateUrl = `${type}/${item.navUrl}`;
    const handleClick = () => {
        navigate(navigateUrl);
        trackEvent({event: `${typeTracking}_${item.trackingId}`, page: page})
    }
    return (
        <div className={`grid-item-container ${type}`} onClick={handleClick}>
            {isAuthenticated && item.isPremium && <FaStar className={'premium-item-icon'}/>}
            <img src={item.thumbnail} alt={`${item.name} icon`}/>
            <div className={"grid-item-info-container"}>
                <h4 className={"learning-grid-item-header"}>{item.name}</h4>
                {(type === "courses" || type === "articles") && (
                    <p className={"learning-grid-item-description"}>{item.description}</p>
                )}
                <div className={"learning-grid-item-icons-list-container"}>
                    {(type === "interviews" || type === "courses") && <GridItemIconInfo icon={ICONS.CLOCK} text={item.time}/>}
                    {type === "courses" && <GridItemIconInfo icon={ICONS.LESSONS} text={`${item.numberOfLessons} Lessons`}/>}
                    {type === "resources" && <GridItemIconInfo icon={ICONS.PDF} text="Download PDF"/>}
                    {type === "articles" && <GridItemIconInfo icon={ICONS.CALENDAR} text={item.date}/>}
                </div>
            </div>
        </div>
    )
}

export const GridItemIconInfo: React.FC<any> = ({icon: Icon, text}) => {
    return (
        <div className={"learning-grid-item-icon-container"}>
            <Icon/>
            <h5>{text}</h5>
        </div>
    )
}