import DevGuidePMIcon from "../images/courses/A Dev’s Guide to Project Management.webp";
import DebuggingIcon from "../images/courses/Debugging_101.webp";
import TechPortfolioIcon from "../images/courses/Building A Tech Portfolio.webp";
import ProgrammingResumeIcon from "../images/courses/How to Write a Programming Resume.webp";
import TechCoverLetterIcon from "../images/courses/How to Write a Tech Cover Letter.webp";
import NonTechInterviewPrepIcon from "../images/courses/Interview Prep (Non Technical).webp";
import TechInterviewPrepIcon from "../images/courses/Interview Prep (Technical).webp";
import LearnFirstLanguageIcon from "../images/courses/Programming - Choosing and Learning Your First Language.webp";
import BecomeProgrammerIcon from "../images/courses/Want to Become a Programmer - Start Here.webp";
import WhatAreAPIsIcon from "../images/courses/What Are APIs and How to Use Them.webp";
import { Course, Lesson, Section } from "../models/Course";

export const PROGRAMMER_RESUMES_COURSES = [
    new Course(
        "Building a Tech Portfolio",
        "Learn how to create the greatest tool in your job searching arsenal: a solid portfolio",
        "25 mins",
        TechPortfolioIcon,
        9,
        [
            "Learn why portfolios are your greatest asset while job searching",
            "Explore the two types of portfolios: websites and binders",
            "Understand how to turn your work into a great portfolio",
            "Get tips on how to use your binder during the interview"
        ],
        [
            new Section(0, "Why You Need One", [
                new Lesson(0, "Overview", "overview", "Building a Tech Portfolio/1 - Overview.mp4"),
                new Lesson(1, "Why Do I Need a Portfolio?", "why-do-i-need-a-portfolio", "Building a Tech Portfolio/2 - Why do I need a portfolio.mp4")
            ]),
            new Section(1, "Preparation", [
                new Lesson(0, "Gathering Your Projects", "gathering-your-projects", "Building a Tech Portfolio/3 - Gathering Your Projects.mp4"),
                new Lesson(1, "Putting It Together", "putting-it-together", "Building a Tech Portfolio/4 - Putting it together.mp4")
            ]),
            new Section(2, "Types of Portfolios", [
                new Lesson(0, "Digital Portfolios", "digital-portfolios", "Building a Tech Portfolio/5 - Digital Portfolios.mp4"),
                new Lesson(1, "Physical Portfolios", "physical-portfolios", "Building a Tech Portfolio/6 - Physical Portfolio.mp4")
            ]),
            new Section(3, "How to Use It", [
                new Lesson(0, "My Portfolio Example", "my-portfolio-example", "Building a Tech Portfolio/7 - My Portfolio Example.mp4"),
                new Lesson(1, "Using Them in an Interview", "using-them-in-an-interview", "Building a Tech Portfolio/8 - Using them in an interview.mp4"),
                new Lesson(2, "Conclusion", "conclusion", "Building a Tech Portfolio/9 - Conclusion.mp4")
            ])
        ],
        "tech-portfolio",
        "TechPortfolio",
        true
    ),
    new Course(
        "How to Write Your Tech Resume",
        "On average, there are 118 applicants for every one job. Learn how to build a resume that stands apart from the rest",
        "1h 33 mins",
        ProgrammingResumeIcon,
        19,
        [
            "Learn a step-by-step guide to standing out",
            "Explore fonts, styles, and format recommendations",
            "View example resumes from industry experts",
            "Get tips on how to make an impact – quickly"
        ],
        [
            new Section(0, "Course Overview", [
                new Lesson(0, "Intro", "intro", "How to Write Your Tech Resume/Resumes 01 - Intro.mp4"),
                new Lesson(1, "About Me", "about-me", "How to Write Your Tech Resume/Resumes 02 - About Me.mp4")
            ]),
            new Section(1, "Stats", [
                new Lesson(0, "Resume Statistics", "resume-statistics", "How to Write Your Tech Resume/Resumes 03 - Stats.mp4")
            ]),
            new Section(2, "Resume Sections", [
                new Lesson(0, "Sections Overview", "sections-overview", "How to Write Your Tech Resume/Resumes 04 - Section Overview.mp4"),
                new Lesson(1, "Contact Info", "contact-info", "How to Write Your Tech Resume/Resumes 05 - Contact info.mp4"),
                new Lesson(2, "Summary", "summary", "How to Write Your Tech Resume/Resumes 06 - Summary.mp4"),
                new Lesson(3, "Work Experience", "work-experience", "How to Write Your Tech Resume/Resumes 07 - Work Experience.mp4"),
                new Lesson(4, "Education", "education", "How to Write Your Tech Resume/Resumes 08 - Education.mp4"),
                new Lesson(5, "Skills", "skills", "How to Write Your Tech Resume/Resumes 09 - Skills.mp4")
            ]),
            new Section(3, "Real Example Resumes", [
                new Lesson(0, "Certifications", "certifications", "How to Write Your Tech Resume/Resumes 10 - Certifications Etc.mp4"),
                new Lesson(1, "My Resume", "my-resume", "How to Write Your Tech Resume/Resumes 11 - My Resume Example.mp4"),
                new Lesson(2, "Cloud Architect Example", "cloud-architect-example", "How to Write Your Tech Resume/Resumes 12 - Example Cloud Architect.mp4"),
                new Lesson(3, "Software Engineer Example", "software-engineer-example", "How to Write Your Tech Resume/Resumes 13 - Example SWE.mp4"),
                new Lesson(4, "Designer Example", "designer-example", "How to Write Your Tech Resume/Resumes 14 - Example Design.mp4")
            ]),
            new Section(4, "General Tips", [
                new Lesson(0, "Senior Software Engineer", "senior-software-engineer", "How to Write Your Tech Resume/Resumes 15 - Example Sen SWE.mp4"),
                new Lesson(1, "Dos and Don’ts", "dos-and-donts", "How to Write Your Tech Resume/Resumes 16 - Dos and Donts.mp4"),
                new Lesson(2, "Font Recommendations", "font-recommendations", "How to Write Your Tech Resume/Resumes 17 - Font Recommendations.mp4"),
                new Lesson(3, "Header Recommendations", "header-recommendations", "How to Write Your Tech Resume/Resumes 18 - Header Recommendations.mp4"),
                new Lesson(4, "Final Tips & Closing", "final-tips", "How to Write Your Tech Resume/Resumes 19 - Final Tips and Closing.mp4")
            ])
        ],
        "tech-resume",
        "TechResume",
        true
    ),
    new Course(
        "How to Write a Tech Cover Letter",
        "There’s a science to getting noticed – and we know the formula",
        "26 mins",
        TechCoverLetterIcon,
        7,
        [
            "Learn why the cover letter matters",
            "Get guidance on how to make an impact",
            "See example letters to examine and reference",
            "Explore general Dos and Dont's"
        ],
        [
            new Section(0, "Overview", [
                new Lesson(0, "Overview", "overview", "How to Write a Tech Cover Letter/Cover Letter 1 - Overview.mp4"),
                new Lesson(1, "Goals", "goals", "How to Write a Tech Cover Letter/Cover Letter 2 - Goals.mp4"),
                new Lesson(2, "Do I Need One?", "do-i-need-one", "How to Write a Tech Cover Letter/Cover Letter 3 - Do I Need One_.mp4")
            ]),
            new Section(1, "Sections", [
                new Lesson(0, "Sections", "sections", "How to Write a Tech Cover Letter/Cover Letter 4 - Sections.mp4")
            ]),
            new Section(2, "Examples", [
                new Lesson(0, "Blank Example", "blank-example", "How to Write a Tech Cover Letter/Cover Letter 5 - Blank Example.mp4"),
                new Lesson(1, "Real Example", "real-example", "How to Write a Tech Cover Letter/Cover Letter 6 - Real Example.mp4")
            ]),
            new Section(3, "Final Tips", [
                new Lesson(0, "Dos and Don’ts", "dos-and-donts", "How to Write a Tech Cover Letter/Cover Letter 7 - Dos _ Dont_s.mp4")
            ])
        ],
        "tech-cover-letter",
        "TechCoverLetter",
        true
    )
];

export const LEARNING_TO_CODE_COURSES = [
    new Course(
        "Want to Become a Programmer? Start Here",
        "First things first: Let's learn what programming is and, more importantly, if you should become a programmer",
        "38 mins",
        BecomeProgrammerIcon,
        9,
        [
            "Understand what programming is",
            "Learn what programmers do",
            "Find out if you could become a programmer",
            "See how code works with examples",
            "See what the life of a programmer is like"
        ],
        [
            new Section(0, "What is a Programmer?", [
                new Lesson(0, "Course Overview", "course-overview", "Want to Become a Programmer? Start Here/1 - Overview.mp4"),
                new Lesson(1, "What is a Programmer?", "what-is-a-programmer", "Want to Become a Programmer? Start Here/2 - What is a Programmer_.mp4")
            ]),
            new Section(1, "Programming Languages", [
                new Lesson(0, "Programming Languages", "programming-languages", "Want to Become a Programmer? Start Here/3 - Programming Languages.mp4"),
                new Lesson(1, "Programming Languages Vs. Human Languages", "programming-languages-vs-human-languages", "Want to Become a Programmer? Start Here/4 - Programming Languages Vs Human Languages.mp4"),
                new Lesson(2, "Comparing Computer Languages", "comparing-computer-languages", "Want to Become a Programmer? Start Here/5 - Comparing Computer Languages.mp4")
            ]),
            new Section(2, "How Computers Think", [
                new Lesson(0, "Computers are… Kind of Dumb", "computers-are-kind-of-dumb", "Want to Become a Programmer? Start Here/6 - Computers are... Kinda Dumb.mp4"),
                new Lesson(1, "Computers Need Details", "computers-need-details", "Want to Become a Programmer? Start Here/7 - Computers Need Details.mp4")
            ]),
            new Section(3, "Becoming a Developer", [
                new Lesson(0, "Developer’s Life", "developers-life", "Want to Become a Programmer? Start Here/8 - Developer_s Life.mp4"),
                new Lesson(1, "Could I Become a Developer?", "could-i-become-a-developer", "Want to Become a Programmer? Start Here/9 - Could I Become A Developer_.mp4"),
                new Lesson(2, "Conclusion", "conclusion", "Want to Become a Programmer? Start Here/10 - Conclusion.mp4")
            ])
        ],
        "become-programmer",
        "BecomeProgrammer",
        false
    ),
    new Course(
        "Programming: Choosing and Learning Your First Language",
        "So many languages and so many ways to learn them! Find which language is best for you, and discover the best approaches for learning it",
        "36 mins",
        LearnFirstLanguageIcon,
        9,
        [
            "Explore your tech career paths",
            "Discover the best way to learn that language",
            "Find the right language to learn",
            "Compare education vs self teaching"
        ],
        [
            new Section(0, "Picking Your First Language", [
                new Lesson(0, "Overview", "overview", "Choosing and Learning Your First Language/First Language 1 - Overview.mp4"),
                new Lesson(1, "Picking a Language", "picking-a-language", "Choosing and Learning Your First Language/First Language 2 - Picking a language.mp4")
            ]),
            new Section(1, "The Tech Career Paths", [
                new Lesson(0, "Tech Career Paths", "tech-career-paths", "Choosing and Learning Your First Language/First Language 3 - Paths.mp4"),
                new Lesson(1, "Web Development", "web-development", "Choosing and Learning Your First Language/First Language 4 - Web Development.mp4"),
                new Lesson(2, "iOS Development", "ios-development", "Choosing and Learning Your First Language/First Language 5 - iOS Development.mp4"),
                new Lesson(3, "Android Development", "android-development", "Choosing and Learning Your First Language/First Language 6 - iOS Development.mp4"),
                new Lesson(4, "Data Analyst", "data-analyst", "Choosing and Learning Your First Language/First Language 7 - Data Analyst.mp4")
            ]),
            new Section(2, "Schooling Vs Self Teaching", [
                new Lesson(0, "Schooling", "schooling", "Choosing and Learning Your First Language/First Language 8 - Schooling.mp4"),
                new Lesson(1, "Self-Teaching", "self-teaching", "Choosing and Learning Your First Language/First Language 9 - Self Teaching.mp4"),
                new Lesson(2, "Closing Thoughts", "closing-thoughts", "Choosing and Learning Your First Language/First Language 10 - Closing Thoughts.mp4")
            ])
        ],
        "choose-first-language",
        "ChooseFirstLanguage",
        true
    ),
    new Course(
        "Debugging 101: Finding and Fix Errors in Your Code",
        "You can't totally prevent writing bugs, but you can certainly learn to debug more effectively. Learn the skills and tricks to save yourself endless hours of headaches!",
        "2h 30 mins",
        DebuggingIcon,
        19,
        [
            "Learn the different types of coding errors",
            "Understand what the Stack Trace is and how to use it",
            "General bug preventing advice",
            "Go step by step through solving every type",
            "Use the Debugger to quickly find issues"
        ],
        [
            new Section(0, "What Is Debugging?", [
                new Lesson(0, "Course Overview", "course-overview", "debugging-101/01_course_overview.mp4"),
                new Lesson(1, "What Is Debugging?", "what-is-debugging", "debugging-101/02_what_is_debugging.mp4"),
                new Lesson(2, "Types of Errors In Programming", "types-of-errors", "debugging-101/03_types_of_errors.mp4")
            ]),
            new Section(1, "Compile Errors", [
                new Lesson(0, "What Are Compile Errors?", "compile-errors", "debugging-101/04_compile_errors.mp4"),
                new Lesson(1, "Compile Errors Examples", "compile-errors-examples", "debugging-101/05_compile_error_debugging_example.mp4"),
                new Lesson(2, "Compiled Vs. Interpreted Languages", "compiled-vs-interpreted", "debugging-101/06_compiled_vs_interpreted_languages.mp4")
            ]),
            new Section(2, "Runtime Errors", [
                new Lesson(0, "What Are Runtime Errors?", "runtime-errors", "debugging-101/07_runtime_errors.mp4"),
                new Lesson(1, "What Is the Stack Trace?", "stack-trace", "debugging-101/08_what_is_the_stack_trace.mp4"),
                new Lesson(2, "Using the Stack Trace", "using-the-stack-trace", "debugging-101/09_using_the_stack_trace.mp4"),
                new Lesson(3, "Runtime Error Examples Part 1", "runtime-error-examples-1", "debugging-101/10_runtime_error_debugging_example.mp4"),
                new Lesson(4, "Runtime Error Examples Part 2", "runtime-error-examples-2", "debugging-101/11_runtime_error_debugging_example_part_2.mp4"),
                new Lesson(5, "Runtime Error Examples Part 3", "runtime-error-examples-3", "debugging-101/12_runtime_error_debugging_example_part_3.mp4")
            ]),
            new Section(3, "Logic Errors", [
                new Lesson(0, "What Are Logic Errors?", "logic-errors", "debugging-101/13_logic_errors.mp4"),
                new Lesson(1, "Learning the Debugger Part 1", "learning-the-debugger-1", "debugging-101/14_learning_the_debugger_part_1.mp4"),
                new Lesson(2, "Learning the Debugger Part 2", "learning-the-debugger-2", "debugging-101/15_learning_the_debugger_part_2.mp4"),
                new Lesson(3, "Printing and Logging", "printing-and-logging", "debugging-101/16_printing_and_logging.mp4")
            ]),
            new Section(4, "Other Debugging Methods", [
                new Lesson(0, "Rubber Ducking Method", "rubber-ducking", "debugging-101/17_rubber_ducking.mp4"),
                new Lesson(1, "General Bug Preventing", "general-bug-preventing", "debugging-101/18_general_bug_preventing.mp4"),
                new Lesson(2, "Debugging With AI", "debugging-with-ai", "debugging-101/19_debugging_with_ai.mp4"),
                new Lesson(3, "Conclusion", "conclusion", "debugging-101/20_conclusion.mp4")
            ])
        ],
        "debugging-101",
        "Debugging101",
        true
    ),
];
export const TECH_CONCEPTS_COURSES = [
    new Course(
        "What Are APIs And How To Use Them",
        "APIs are the key to creating more advanced apps and websites, but they can be a bit tricky at first. Let's dive into how they work!",
        "50 mins",
        WhatAreAPIsIcon,
        16,
        [
            "Learn what an API is",
            "Understand how to read different API responses",
            "Discover the types of HTTP Requests and Responses",
            "Walk through using a real weather API"
        ],
        [
            new Section(0, "What Is An API?", [
                new Lesson(0, "Introduction and Course Overview", "introduction-overview", "what-are-apis/1 - Introduction and Overview.mp4"),
                new Lesson(1, "What’s An API?", "whats-an-api", "what-are-apis/2 - What_s an API_.mp4"),
                new Lesson(2, "Logging In Example", "logging-in-example", "what-are-apis/3 - Logging In.mp4"),
                new Lesson(3, "Types of APIs", "types-of-apis", "what-are-apis/4 - Public & Private APIs.mp4")
            ]),
            new Section(1, "HTTP Requests and Responses", [
                new Lesson(0, "HTTP Requests", "http-requests", "what-are-apis/5 - HTTP Requests.mp4"),
                new Lesson(1, "HTTP Responses", "http-responses", "what-are-apis/6 - Types of HTTP Responses.mp4")
            ]),
            new Section(2, "Let's Use an API!", [
                new Lesson(0, "Real API Example", "real-api-example", "what-are-apis/7 - API Example.mp4"),
                new Lesson(1, "Making An API Call", "making-api-call", "what-are-apis/8 - Making an API Call.mp4"),
                new Lesson(2, "Building Our API Example", "building-api-example", "what-are-apis/9 - Python Example.mp4"),
                new Lesson(3, "JSON: Talking with the API", "json-api", "what-are-apis/10 - Talking with an API_ JSON.mp4"),
                new Lesson(4, "Reading Our Response", "reading-response", "what-are-apis/11 - Reading Our Example.mp4"),
                new Lesson(5, "Manipulating Our Data", "manipulating-data", "what-are-apis/12 - Using Our Data.mp4"),
                new Lesson(6, "Putting It All Together", "putting-it-all-together", "what-are-apis/13 - Putting It All Together.mp4")
            ]),
            new Section(3, "Authentication", [
                new Lesson(0, "API Authentication Overview", "api-authentication-overview", "what-are-apis/14 - API Authentication.mp4"),
                new Lesson(1, "API Authentication Overview Pt 2", "api-authentication-overview-pt2", "what-are-apis/15 - API Authentication (Cont.).mp4")
            ]),
            new Section(4, "Conclusion", [
                new Lesson(0, "Summary and Closing Thoughts", "summary-closing-thoughts", "what-are-apis/16 - Conclusion.mp4")
            ])
        ],
        "what-are-apis",
        "WhatAreApis",
        true
    ),
    new Course(
        "A Dev’s Guide to Project Management: Agile, Scrum, & More!",
        "Understanding the basics of Project Management will help immensely in interviews and on the job. Learn everything you need to know as a developer!",
        "1h 18 mins",
        DevGuidePMIcon,
        23,
        [
            "Learn everything you need to know about PM from a Developer's perspective",
            "Learn about the two main types of Project Management",
            "Understand what Agile is and why its important",
            "Learn the process of Scrum"
        ],
        [
            new Section(0, "What Is Project Management?", [
                new Lesson(0, "Course Overview", "course-overview", "devs-guide-to-pm/01 Introduction and Course Overview.mp4"),
                new Lesson(1, "What is Project Management?", "what-is-project-management", "devs-guide-to-pm/02 What is Project Management_.mp4")
            ]),
            new Section(1, "Process Overview", [
                new Lesson(0, "A Typical Workflow", "typical-workflow", "devs-guide-to-pm/03 A Typical Workflow.mp4"),
                new Lesson(1, "PM Gives Devs Needed Work", "pm-gives-devs-work", "devs-guide-to-pm/04 1.PM Gives Devs Needed Work.mp4"),
                new Lesson(2, "Team Breaks Down Work", "team-breaks-down-work", "devs-guide-to-pm/05 2.Team Breaks Work into Categories.mp4"),
                new Lesson(3, "Example of Breaking Down Work", "breaking-down-example", "devs-guide-to-pm/06 Breakdown Example.mp4"),
                new Lesson(4, "Team Estimates How Long Things Will Take", "team-estimates", "devs-guide-to-pm/07 3.Team Estimates How Long Things Will Take.mp4"),
                new Lesson(5, "Time vs Size Estimating", "time-vs-size-estimating", "devs-guide-to-pm/08 The Greate Debate-Time vs. Size & Size Estimating.mp4"),
                new Lesson(6, "Why Sizing Helps", "why-sizing-helps", "devs-guide-to-pm/09 Why Sizing Helps.mp4"),
                new Lesson(7, "Beginning Work and Tracking It", "beginning-work-tracking", "devs-guide-to-pm/10 4.Work Begins and Is Tracked.mp4"),
                new Lesson(8, "Reevaluating the Timeline", "reevaluating-timeline", "devs-guide-to-pm/11 5.(Optional) Reevaluate Timeline and Estimations while Working on the Project.mp4"),
                new Lesson(9, "Testing Your Work", "testing-your-work", "devs-guide-to-pm/12 6.Test Your Work.mp4"),
                new Lesson(10, "Deliver Your Work", "deliver-your-work", "devs-guide-to-pm/13 7.Deliver the Project.mp4")
            ]),
            new Section(2, "Agile Overview: Scrum & Kanban", [
                new Lesson(0, "Comparing Waterfall vs Agile", "waterfall-vs-agile", "devs-guide-to-pm/14 Comparing the Methods.mp4"),
                new Lesson(1, "Why Companies Choose Agile", "why-companies-choose-agile", "devs-guide-to-pm/15 Why Companies Choose Agile.mp4"),
                new Lesson(2, "Overview of Agile", "overview-of-agile", "devs-guide-to-pm/16 Overview of Agile.mp4"),
                new Lesson(3, "King of Agile: Scrum", "king-of-agile-scrum", "devs-guide-to-pm/17 King of Agile - Scrum.mp4"),
                new Lesson(4, "The Scrum Team", "scrum-team", "devs-guide-to-pm/18 Scrum Team.mp4"),
                new Lesson(5, "Scrum Ceremonies", "scrum-ceremonies", "devs-guide-to-pm/19 Scrum Ceremonies.mp4"),
                new Lesson(6, "Scrum Diagram", "scrum-diagram", "devs-guide-to-pm/20 Scrum Diagram.mp4"),
                new Lesson(7, "Nonstop Agile: Kanban", "nonstop-agile-kanban", "devs-guide-to-pm/21 Nonstop Agile - Kanban.mp4")
            ]),
            new Section(3, "Methods in Practice", [
                new Lesson(0, "Methods in Practice", "methods-in-practice", "devs-guide-to-pm/22 Methods in Practice.mp4"),
                new Lesson(1, "Conclusion", "conclusion", "devs-guide-to-pm/23 Conclusion.mp4")
            ])
        ],
        "dev-guide-to-project-management",
        "DevGuideToProjectManagement",
        true
    )
];

export const TECH_INTERVIEW_PREP_COURSES = [
    new Course(
        "Interview Prep (Technical)",
        "Learn how to make those tricky tech interviews a breeze",
        "32 mins",
        TechInterviewPrepIcon,
        8,
        [
            "Understand what a technical interview involves",
            "Find the best ways to study and prepare for them",
            "Discover what the interviewer is really trying to determine",
            "Explore how much time should be spent learning computer science concepts"
        ],
        [
            new Section(0, "Tech Interviews Overview", [
                new Lesson(0, "Overview", "overview", "Interview Prep (Technical)/Technical - 1. Overview.mp4"),
                new Lesson(1, "Interviewing in Tech", "interviewing-in-tech", "Interview Prep (Technical)/Technical - 2. Interviewing in Tech.mp4")
            ]),
            new Section(1, "How to Prepare", [
                new Lesson(0, "Coding Interviews Vary", "coding-interviews-vary", "Interview Prep (Technical)/Technical - 3. Coding Interviews Vary.mp4"),
                new Lesson(1, "Reviewing the Job Description", "reviewing-job-description", "Interview Prep (Technical)/Technical - 4. Reviewing the Job Description.mp4"),
                new Lesson(2, "Practicing Without Tools", "practicing-without-tools", "Interview Prep (Technical)/Technical - 5. Practicing Without Tools.mp4"),
                new Lesson(3, "Thought Process", "thought-process", "Interview Prep (Technical)/Technical - 6. Thought Process.mp4")
            ]),
            new Section(2, "Computer Science Concepts", [
                new Lesson(0, "Do I Need to Know Computer Science Concepts? Part 1 & 2", "do-i-need-to-know-computer-science-concepts", "Interview Prep (Technical)/Technical - 7. Do I Need to Know Computer Science Concepts_ Part 1 and 2.mp4"),
                new Lesson(1, "Conclusion", "conclusion", "Interview Prep (Technical)/Technical - 8. Conclusion.mp4")
            ])
        ],
        "interview-prep-technical",
        "InterviewPrepTechnical",
        true
    ),
    new Course(
        "Interview Prep (Non Technical)",
        "Passing a tech interview requires a lot more than writing code!",
        "41 mins",
        NonTechInterviewPrepIcon,
        9,
        [
            "Understand The Process of Typical Tech Interviews",
            "Create Your Own List of Questions to Bring",
            "Learn All the Questions You Should Prepare to Answer",
            "Discover the Best Way to Prepare"
        ],
        [
            new Section(0, "Interviewing in Tech", [
                new Lesson(0, "Overview", "overview", "Interview Prep (Non Technical)/Non-Technical - 1. Overview.mp4"),
                new Lesson(1, "Interviewing in Tech", "interviewing-in-tech", "Interview Prep (Non Technical)/Non-Technical - 2. Interviewing in Tech.mp4")
            ]),
            new Section(1, "Succeeding in Interviews", [
                new Lesson(0, "Learn About the Company", "learn-about-the-company", "Interview Prep (Non Technical)/Non-Technical - 3. Learn About the Company.mp4"),
                new Lesson(1, "What to Wear?", "what-to-wear", "Interview Prep (Non Technical)/Non-Technical - 4. What to Wear.mp4"),
                new Lesson(2, "How to Answer Questions", "how-to-answer-questions", "Interview Prep (Non Technical)/Non-Technical - 5. How to Answer Questions.mp4")
            ]),
            new Section(2, "Preparation", [
                new Lesson(0, "Common Questions You’ll Be Asked", "common-questions", "Interview Prep (Non Technical)/Non-Technical - 6. Common Questions You_ll be Asked.mp4"),
                new Lesson(1, "How to Prepare?", "how-to-prepare", "Interview Prep (Non Technical)/Non-Technical - 7. How to Prepare_.mp4"),
                new Lesson(2, "Bring Your Own Questions!", "bring-your-own-questions", "Interview Prep (Non Technical)/Non-Technical - 8. Bring Your Own Questions!.mp4"),
                new Lesson(3, "Conclusion", "conclusion", "Interview Prep (Non Technical)/Non-Technical - 9. Conclusion.mp4")
            ])
        ],
        "interview-prep-non-technical",
        "InterviewPrepNonTechnical",
        true
    )
];