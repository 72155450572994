import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { LandingPage } from "./pages/LandingPage";
import "./css/Global.css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { LearningContentPage } from "./pages/LearningContentPage";
import { LessonPage } from "./pages/LessonPage";
import { AccountPage } from "./pages/AccountPage";
import initializeStripe from "./util/InitializeStripe";
import ScrollToTop from "./util/ScrollToTop";
import { PremiumOverlay } from "./components/PremiumOverlay";
import { isUserPremium } from "./util/APIHelper";
import { EmailSignUpPage } from "./pages/EmailSignUpPage";
import { RegisterPage } from "./pages/RegisterPage";
import { Analytics } from "@vercel/analytics/react"
import {EmailSubscribeOverlay} from "./components/EmailSubscribeOverlay";

function App() {
    const [showAuth, setShowAuth] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [isCheckingPremium, setIsCheckingPremium] = useState(false);
    const [user, setUser] = useState(null);
    const [showPremiumOverlay, setShowPremiumOverlay] = useState(false);

    useEffect(() => {
        initializeStripe().then(() => {
            console.log('Stripe initialized');
        }).catch((error) => {
            console.error('Error initializing Stripe:', error);
        });

        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setIsAuthenticated(true);
                setShowAuth(false);
            } else {
                setIsAuthenticated(false);
                setIsPremium(false);
                setUser(null);
                setShowPremiumOverlay(false);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const checkPremiumStatus = async () => {
            console.log("Checking Premium Status...");
            if (user) {
                setIsCheckingPremium(true);
                const premiumStatus = await isUserPremium(user.uid);
                setIsPremium(premiumStatus);
                setIsCheckingPremium(false);
            }
        };
        checkPremiumStatus();
    }, [user]);

    return (
        <Router>
            <ScrollToTop />
            <Analytics />
            <div className="app-container">
                <ConditionalLayout isAuthenticated={isAuthenticated}>
                    {isCheckingPremium ? (<p>Loading...</p>) : (
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <LandingPage
                                        isAuthenticated={isAuthenticated}
                                        isPremium={isPremium}
                                        setShowAuth={setShowAuth}
                                        setShowPremiumOverlay={setShowPremiumOverlay}
                                    />
                                }
                            />
                            <Route path="/register" element={<RegisterPage />} />
                            <Route path="/login" element={<RegisterPage isLogin={true}/>} />
                            <Route path="/account" element={<AccountPage />} />
                            <Route path="/:contentType/:contentName"
                                element={
                                    <LearningContentPage
                                        isAuthenticated={isAuthenticated}
                                        isPremium={isPremium}
                                        setShowAuth={setShowAuth}
                                        setShowPremiumOverlay={setShowPremiumOverlay}
                                    />
                                }
                            />
                            <Route
                                path="/courses/:course/:sectionIdParam/:lessonIdParam"
                                element={
                                    <LessonPage
                                        isAuthenticated={isAuthenticated}
                                        isPremium={isPremium}
                                        setShowPremiumOverlay={setShowPremiumOverlay}
                                    />
                                }
                            />
                            <Route path="/email-signup/:type" element={<EmailSignUpPage />} />
                        </Routes>
                    )}
                </ConditionalLayout>
                {showAuth && !isAuthenticated && <EmailSubscribeOverlay setShowAuth={setShowAuth} />}
                {showPremiumOverlay && <PremiumOverlay user={user} setShowPremiumOverlay={setShowPremiumOverlay} />}
            </div>
        </Router>
    );
}

const ConditionalLayout = ({children, isAuthenticated,}) => {
    const location = useLocation();
    const isRegisterPage = location.pathname === "/register" || location.pathname === "/login";

    return (
        <>
            {!isRegisterPage && (<Header isAuthenticated={isAuthenticated}/>)}
            {children}
            {!isRegisterPage && <Footer />}
        </>
    );
};

export default App;