import {FaRegClock, FaRegFilePdf, FaRegCircle, FaCircle, FaArrowLeft, FaArrowRight, FaChevronCircleRight, FaPlayCircle, FaUnlockAlt} from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaListUl } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaStar } from "react-icons/fa";

export const ICONS = {
    CLOCK: FaRegClock,
    PDF: FaRegFilePdf,
    LESSONS: FaListUl,
    CLOSE: IoCloseOutline,
    SOLID_CIRCLE: FaCircle,
    HOLLOW_CIRCLE: FaRegCircle,
    CHECKMARK_CIRCLE: IoIosCheckmarkCircle,
    PREMIUM: FaStar,
    LEFT_ARROW: FaArrowLeft,
    RIGHT_ARROW: FaArrowRight,
    CALENDAR: CiCalendar,
    HAMBURGER: GiHamburgerMenu,
    RIGHT_ARROW_CIRCLE: FaChevronCircleRight,
    PLAY_CIRCLE: FaPlayCircle,
    UNLOCK: FaUnlockAlt
}