import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ToolType } from "../models/ToolType";
import "../css/FileUploader.css"

interface FileUploaderProps {
    toolType: ToolType;
    onFileUpload: (file: File) => void;
    isPremium: boolean;
    setShowPremiumOverlay: any;
}

export const FileUploader: React.FC<FileUploaderProps> = ({ toolType, onFileUpload, isPremium, setShowPremiumOverlay }) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) return;
        if (!isPremium) {
            setShowPremiumOverlay(true)
        } else {
            onFileUpload(acceptedFiles[0]);
        }
    }, [onFileUpload]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isPremium) {
            event.preventDefault();
            setShowPremiumOverlay(true);
        } else {
            getRootProps().onClick!(event);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        maxFiles: 1,
        accept: toolType.uploaderFileTypes,
    });

    const supportedFileTypes = Object.values(toolType.uploaderFileTypes)
        .flat()
        .join(", ");

    return (
        <div className={'file-uploader-container'}
            {...getRootProps()}
            onClick={handleClick}
            style={{
                border: "2px dashed #aaa",
                backgroundColor: isDragActive ? "#eee" : "#fff",
                margin: "1rem 0",
            }}
        >
            <input {...getInputProps()} />
            <p>{toolType.uploaderText}</p>
            <p className={'supports-file-types'}>Supports: {supportedFileTypes}</p>
        </div>
    );
};