import {GenericContent} from "./GenericContent";

export class Article extends GenericContent {
    content: string;
    date: string;

    constructor(
        name: string,
        description: string,
        thumbnail: string,
        navUrl: string,
        content: string,
        date: string,
        trackingId: string
    ) {
        super(name, description, thumbnail, navUrl, trackingId, false);
        this.content = content;
        this.date = date;
    }
}