import React, { useEffect, useRef } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../util/Firebase';
import videojs from 'video.js';
import '../css/VideoPlayer.css';
import 'video.js/dist/video-js.css';

export const VideoPlayer: React.FC<any> = ({ premiumContent, fileUrl, isPremium }) => {
    const videoRef = useRef<any | null>(null);
    const playerRef = useRef<any | null>(null);

    useEffect(() => {
        if (videoRef.current && !playerRef.current) {
            const storageRef = ref(storage, fileUrl);
            getDownloadURL(storageRef)
                .then((url) => {
                    playerRef.current = videojs(videoRef.current, {
                        controls: true,
                        autoplay: false,
                        preload: 'auto',
                        sources: [{
                            src: url,
                            type: 'video/mp4'
                        }]
                    });
                })
                .catch((error) => {
                    console.error("Error fetching video URL:", error);
                });
        }

        return () => {
            if (playerRef.current) {
                console.log("Disposing Video.js player...");
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, [fileUrl]);

    if (!isPremium && premiumContent) {
        return null;
    }

    return (
        <div className="video-container">
            <video ref={videoRef} className="video-js vjs-default-skin video-player" />
        </div>
    );
};

export default VideoPlayer;