import React from 'react';
import {HeroImage} from "../components/HeroImage";
import {MembershipOverviewModule} from "../components/MembershipOverviewModule";
import "../css/GenericPage.css"
import {AboutMeModule} from "../components/AboutMeModule";
import {TestimonialsModule} from "../components/TestimonialsModule";
import {AllContent} from "../components/LearningPathContent";
import {FinalCTA} from "../components/FinalCTA";
import {ComparisonGrid} from "../components/ComparisonGrid";
import {DiscordJoin} from "../components/DiscordJoin";

export const LandingPage: React.FC<any> = ({ isAuthenticated, isPremium, setShowAuth, setShowPremiumOverlay }) => {
    return (
        <div className="page-container">
            <HeroImage isAuthenticated={isAuthenticated} />
            {!isAuthenticated && <MembershipOverviewModule/>}
            {!isAuthenticated && <ComparisonGrid/>}
            {!isAuthenticated && <TestimonialsModule/>}
            <AllContent isAuthenticated={isAuthenticated} />
            {!isAuthenticated && <AboutMeModule/>}
            {!isAuthenticated && <FinalCTA/>}
            {isAuthenticated && <DiscordJoin />}
        </div>
    );
};