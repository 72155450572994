import {ToolType} from "../models/ToolType";

export const RESUME_REVIEWER = 'RESUME_REVIEWER'

export const TOOL_TYPES_CONFIG = [
    new ToolType(
        "resume-reviewer",
        `AI Powered Resume Reviewer`,
        'Trained Specifically for Tech Resumes',
        RESUME_REVIEWER,
        [
            "Evaluates structure and clarity",
            "Provides personalized feedback",
            "Suggests relevant programming keywords",
            "Identifies potential gaps",
            "Recommends best practices"
        ],
        {
            "application/pdf": [".pdf"]
        },
        "Drag & drop your resume here, or click to select files"
    )
]