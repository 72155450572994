import React from "react";
import {DisplayBars} from "./DisplayBars";
import "../css/FinalCTA.css"
import {EmailSubscribeInput} from "./EmailSubscribeInput";

export const FinalCTA: React.FC<any> = ({}) => {
    return (
        <div className={`final-cta-container`}>
            <DisplayBars position={'top'}/>
            <DisplayBars position={'bottom'}/>
            <h4>Your tech Career Awaits...<br/>What Do you have to lose?</h4>
            <EmailSubscribeInput />
        </div>
    )
}