import React from "react";
import "../css/ResumeUploadedModule.css"
import { FaFileAlt } from "react-icons/fa";

export const ResumeUploadedModule: React.FC<{ file: File }> = ({file}) => {

    return (
        <div className={'resume-uploaded-container'}>
            <div className={'resume-uploaded-icon-container'}>
                <FaFileAlt id={'resume-uploaded-icon'} />
            </div>
            <div className={'resume-uploaded-text-container'}>
                <h5>Uploaded Resume</h5>
                <p>{file.name}</p>
            </div>
        </div>
    );
};