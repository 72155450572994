import React from 'react';
import "../css/GenericPage.css"
import {useNavigate} from "react-router-dom";

export const GoPremiumButton: React.FC<any> = ({isHeader = false}) => {
    const navigate = useNavigate()
    const text = isHeader ? "Join Now" : "Start Learning - It's Free"
    return (<button className="go-premium-button" onClick={() => navigate("/register")}>{text}</button>);
};

export const LogInButton: React.FC<any> = ({}) => {
    const navigate = useNavigate()
    return (
        <button className="log-in-button" onClick={() => navigate("/login")}>Log In</button>
    );
};