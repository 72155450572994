export class ToolType {

    navUrl: string;
    title: string;
    subtitle: string;
    type: string;
    highlights: string[];
    uploaderFileTypes: { [key: string]: string[] };
    uploaderText: string;

    constructor(
        navUrl: string,
        title: string,
        subtitle: string,
        type: string,
        highlights: string[],
        uploaderFileTypes: { [key: string]: string[] },
        uploaderText: string
    ) {
        this.navUrl = navUrl;
        this.title = title;
        this.subtitle = subtitle;
        this.type = type;
        this.highlights = highlights;
        this.uploaderFileTypes = uploaderFileTypes;
        this.uploaderText = uploaderText;
    }
}