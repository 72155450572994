import React from "react";
import "../css/Header.css";
import LogoMedium from "../images/Logo_Medium.webp";
import { RiAccountBoxFill } from "react-icons/ri";
import {LogInButton} from "./GoPremiumButton";
import {useNavigate} from "react-router-dom";
import {EVENTS, Page, trackEvent, TrackingEvent} from "../util/TrackingHelper";

export const Header: React.FC<any> = ({isAuthenticated}) => {
    const navigate = useNavigate()
    const onHeaderLinkClicked = (navUrl: string, trackingEvent: TrackingEvent) => {
        navigate(navUrl)
        trackEvent(trackingEvent)
    }

    return (
        <div className="header-module-container">
            <div className={"header-module-content"}>
                <img className={"header-logo"} src={LogoMedium} alt={"Beyond Code Logo"} onClick={() => onHeaderLinkClicked("/", EVENTS.HEADER.HEADER_LOGO)}/>
                {isAuthenticated && <RiAccountBoxFill className={"header-account-icon"} onClick={() => onHeaderLinkClicked("/account", EVENTS.HEADER.HEADER_ACCOUNT)}/>}
                {!isAuthenticated && (
                    <div className={'header-logged-out-buttons'}>
                        <div className={'header-logged-out-web-container'}>
                            <LogInButton className={'header-log-in-button'} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};