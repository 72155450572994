import React from "react";
import "../css/MembershipOverviewModule.css";
import {MEMBERSHIP_OVERVIEW_ITEMS} from "../constants/MembershipOverviewConfig";

export const MembershipOverviewModule = () => {
    return (
        <div className={'membership-overview-module-container'}>
            <h3 className={'membership-overview-subtitle'}>We won't teach you to code</h3>
            <div className={'membership-overview-subtitle'}>We'll just teach you.. well.. everything else</div>
            <div className="membership-overview-module-content">
                {MEMBERSHIP_OVERVIEW_ITEMS.map((item, index) => (
                    <div key={index} className={`overview-item`}>
                        <div className="overview-icon">
                            <img src={item.icon} alt={`${item.header} icon`}/>
                        </div>
                        <h4>{item.header}</h4>
                    </div>
                ))}
            </div>
        </div>
    );
};