import {GenericContent} from "./GenericContent";

export class Resource extends GenericContent {

    fileUrl: string;

    constructor(
        name: string,
        description: string,
        thumbnail: string,
        navUrl: string,
        fileUrl: string,
        trackingId: string,
        isPremium: boolean
    ) {
        super(name, description, thumbnail, navUrl, trackingId, isPremium);
        this.fileUrl = fileUrl;
    }
}