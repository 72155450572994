import React from 'react';
import '../css/HeroImage.css';
import DOM_AVATAR from "../images/testimonials/Dominik-Jasarevic-Avatar.webp"
import KAZ_AVATAR from "../images/testimonials/Shane-K-Avatar.webp"
import RYAN_AVATAR from "../images/testimonials/RyanTAvatar.webp"
import {FaStar} from "react-icons/fa";
import {DisplayBars} from "./DisplayBars";
import {EmailSubscribeInput} from "./EmailSubscribeInput";

export const HeroImage: React.FC<any> = ({isAuthenticated}) => {
    return (
        <div className="hero-image-container">
            <DisplayBars position={'top'}/>
            <DisplayBars position={'bottom'}/>
            <div className="hero-text-container">
                <h1>Helping You Build Your</h1>
                <div className="hero-highlighted-text">
                    <span className="highlighted">Coding</span>
                    <span> Career</span>
                </div>
                <p>Whether you’re on day 0, or you’ve programmed a bit,<br/> we'll help you build your tech career</p>
                {!isAuthenticated && <EmailSubscribeInput />}
            </div>
        </div>
    );
};

export const HeroReviews = ({}) => {
    return (
        <div className={'hero-review-container'}>
            {Array(5).fill(null).map((_, i) => (
                <FaStar key={i} className="testimonial-star-icon"/>
            ))}
            <img id={'hero-testimonial-1'} src={KAZ_AVATAR}/>
            <img id={'hero-testimonial-2'} src={DOM_AVATAR}/>
            <img id={'hero-testimonial-3'} src={RYAN_AVATAR}/>
        </div>
    )
}