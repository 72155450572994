import React from 'react';
import '../css/EmailSubscribeInput.css';
import '../css/EmailSubscribeOverlay.css'
import {EmailSubscribeInput} from "./EmailSubscribeInput";
import LogoMedium from "../images/Logo_Medium.webp";
import {ICONS} from "../util/IconHelper";

export const EmailSubscribeOverlay: React.FC<any> = ({setShowAuth}) => {
    return (
        <div className={'overlay-container'} onClick={() => setShowAuth(false)}>
            <div className={'overlay-content'}>
                <img className={"overlay-logo"} src={LogoMedium} alt={"Beyond Code Logo"}/>
                <EmailSubscribeInput/>
                <ICONS.CLOSE className="overlay-container-close" onClick={() => setShowAuth(false)} />
            </div>
        </div>
    );
};
