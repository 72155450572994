import React from "react";
import {DisplayBars} from "./DisplayBars";
import "../css/DiscordJoin.css"
import { FaDiscord } from "react-icons/fa";

export const DiscordJoin: React.FC<any> = ({}) => {
    return (
        <div className={`discord-cta-container`}>
            <DisplayBars position={'top'}/>
            <DisplayBars position={'bottom'}/>
            <h4>Courses are great,<br/>but nothing beats a community</h4>
            <div className={'discord-join-cta'} onClick={() => window.open("https://discord.gg/WUdjg3mDfT")}>
                <div className={'discord-join-cta-text'}>Join Our Discord</div>
                <FaDiscord className={'discord-icon'}/>
            </div>

        </div>
    )
}