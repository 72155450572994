// ResumeReviewerPage.tsx
import React, {useState} from "react";
import "../css/ToolPage.css"
import {useParams} from "react-router-dom";
import {DisplayBars} from "../components/DisplayBars";
import {RESUME_REVIEWER, TOOL_TYPES_CONFIG} from "../constants/ToolTypesConfig";
import {ToolType} from "../models/ToolType";
import {FileUploader} from "../components/FileUploader";
import { IoMdSearch } from "react-icons/io";
import {analyzeUserResume} from "../util/APIHelper";
import {ResumeUploadedModule} from "../components/ResumeUploadedModule";
import {ProgressBar} from "../components/ProgressBar";
import { IoRefreshCircleSharp } from "react-icons/io5";

function getToolPage(url: string): any {
    return TOOL_TYPES_CONFIG.find((toolPage) => toolPage.navUrl === url) || null;
}

export const ResumeReviewerPage: React.FC<any> = ({isPremium, setShowPremiumOverlay}) => {
    const {type} = useParams();
    const toolType = getToolPage(type || "");
    if (!toolType) {
        return <></>;
    }

    return (
        <div className="tool-page-container">
            <DisplayBars position={'top'}/>
            <DisplayBars position={'bottom'}/>
            <div className="tool-page-content">
                <div className="tool-text-container">
                    <h1>{toolType.title}</h1>
                    <h2>{toolType.subtitle}</h2>
                    <ul>
                        {toolType.highlights.map((highlight: string, index: number) => (
                            <li key={index}>{highlight}</li>
                        ))}
                    </ul>
                </div>
                {toolType.type === RESUME_REVIEWER && <ResumeReviewerTool toolType={toolType} isPremium={true} setShowPremiumOverlay={setShowPremiumOverlay}/>}
            </div>
        </div>
    );
};

const ResumeReviewerTool: React.FC<{ toolType: ToolType, isPremium: boolean, setShowPremiumOverlay: any }> = ({ toolType, isPremium, setShowPremiumOverlay }) => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null); // Store file temporarily
    const [analyzingState, setAnalyzingState] = useState<"idle" | "analyzing" | "results">("idle");
    const [analysisResult, setAnalysisResult] = useState<any>(null);

    const handleFileUpload = (file: File) => {
        setUploadedFile(file);
        setAnalysisResult(null);
        setAnalyzingState("idle");
    };

    const handleAnalyzeResume = async () => {
        if (!uploadedFile) return;

        try {
            setAnalyzingState("analyzing");
            const result = await analyzeUserResume(uploadedFile);
            setAnalysisResult(result);
            setAnalyzingState("results");
        } catch (err) {
            console.error("Error analyzing resume:", err);
            setAnalyzingState("idle");
        }
    };

    const onRedoClicked = () => {
        setAnalysisResult(null);
        setUploadedFile(null)
        setAnalyzingState("idle");
    }

    return (
        <div className={`resume-reviewer-container ${analyzingState === "results" ? "results" : ""}`}>
            {analyzingState === "idle" && (
                <div className="resume-reviewer-not-analyzed-container">
                    <div className="resume-reviewer-title-container">
                        <h2>Resume Analyzer</h2>
                        <IoMdSearch id="resume-search-icon"/>
                    </div>
                    {!uploadedFile ? (
                        <FileUploader toolType={toolType} onFileUpload={handleFileUpload} isPremium={isPremium} setShowPremiumOverlay={setShowPremiumOverlay}/>
                    ) : (
                        <div className="resume-reviewer-uploaded-container">
                            <ResumeUploadedModule file={uploadedFile}/>
                        </div>
                    )}
                    <button
                        className="analyze-resume-button"
                        onClick={handleAnalyzeResume}
                        disabled={!uploadedFile}
                    >
                        Analyze
                    </button>
                </div>
            )}
            {analyzingState === "analyzing" && (
                <div className="resume-analyzing-container">
                    <h2>Analyzing your resume...</h2>
                    <ProgressBar length={15}/>
                </div>
            )}
            {analyzingState === "results" && analysisResult && (
                <div className="resume-analysis-result">
                    <IoRefreshCircleSharp onClick={onRedoClicked} id={'resume-analysis-result-redo-icon'}/>
                    <h3>Analysis Results:</h3>
                    <p><strong className="analysis-section-header">Summary:</strong> {analysisResult.summary}</p>
                    <p className="analysis-section-header"><strong>Pros:</strong></p>
                    <ul className="analysis-section-list">
                        {analysisResult.pros.map((pro: string, index: number) => (
                            <li key={index}>{pro}</li>
                        ))}
                    </ul>
                    <p className="analysis-section-header"><strong>Cons:</strong></p>
                    <ul className="analysis-section-list">
                        {analysisResult.cons.map((con: string, index: number) => (
                            <li key={index}>{con}</li>
                        ))}
                    </ul>
                    <p className="analysis-section-header"><strong>Suggestions:</strong></p>
                    <ul className="analysis-section-list">
                        {analysisResult.suggestions.map((suggestion: string, index: number) => (
                            <li key={index}>{suggestion}</li>
                        ))}
                    </ul>
                    <p className="analysis-section-header"><strong>Grammar Mistakes:</strong></p>
                    <ul className="analysis-section-list">
                        {analysisResult.grammar_mistakes.map((mistake: string, index: number) => (
                            <li key={index}>{mistake}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};